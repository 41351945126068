export default function Address() {
  return (
    <div>
      <p className="whitetext">
        Oleksandr Lazurenko
        <br />
        Holzhofallee 36
        <br />
        64295 Darmstadt
        <br />
        Deutschland
      </p>
    </div>
  );
}
