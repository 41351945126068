import Header from "./Header";
import MyTabsCn from "./MyTabsCn";

export default function AppCn() {
  return (
    <div>
      <div className="maincontainer">
        <Header />
        <MyTabsCn />
      </div>
    </div>
  );
}
